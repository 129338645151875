<template>
  <div class="sdk-main">
    <div data-page="bornToMix">
      <!-- <BannerVideoSection video="https://www.youtube.com/embed/fxfO9XUCkHo" :text="true" isYoutube="true" /> -->
      <BannerVideoSection
      video="https://www.youtube.com/embed/5F6S3WhVJIc"
      :text="true"
       isYoutube="true"
       :icon="true"
       :iconMobile="true"

       />
      <EnjoySection />

      <section class="sdk-cocktails">
        <CocktailsSection />

        <BannerVideoSection
        video="https://plant.hellosandia.com/img/test/video3PinaColada.mp4"
        :text="false"
        :isYoutube="false"
        :icon="true"
        :iconMobile="false"
         />

        <FlipCardStrawberry />

        <FlipCardLeftInfo style="display:none" />
        <MapSection style="display:none" />
        <FlipCardLeft  style="display:none"/>
        <BannerVideoSection
        video="https://plant.hellosandia.com/img/test/VideoBanner2UK.mp4"
        :text="false"
        :isYoutube="false"
        :icon="true"
        :iconMobile="false"
         />
        <BannerImageSection  style="display: none;"/>
      </section>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import MapSection from '@/components/MapSection.ce.vue';
import FlipCardLeft from '@/components/FlipCardLeft.ce.vue';
import BannerVideoSection from '@/components/BannerVideoSection.ce.vue';
import FlipCardLeftInfo from '@/components/FlipCardLeftInfo.ce.vue';
import CocktailsSection from '@/components/CocktailsSection.ce.vue';
import EnjoySection from '@/components/EnjoySection.ce.vue';
import BannerImageSection from '@/components/BannerImageSection.ce.vue';
import FlipCardStrawberry from '@/components/FlipCardStrawberry.ce.vue';

export default {
  name: 'HomeView',
  components: {
    MapSection,
    BannerVideoSection,
    FlipCardLeft,
    FlipCardLeftInfo,
    CocktailsSection,
    BannerImageSection,
    EnjoySection,
    FlipCardStrawberry,
  },
  data() {
    return {};
  },
  methods: {
    // async testAPI() {
    //   console.log('Testing API...');
    //   const { data } = await API.get('/test');
    //   console.log(data);
    // },
    // async log(message) {
    //   console.log('Testing API DB...');
    //   const payload = {
    //     interaction: message,
    //   };
    //   const { data } = await API.post('/log', payload);
    //   console.log(data);
    // },
  },

};
</script>
