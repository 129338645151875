<template>
  <div class="map-component" style="    height: 100%;">
    <div class="overlay" v-if="selectedState == null">
      <img src="https://archeio2.nyc3.cdn.digitaloceanspaces.com/malibu/2023_pinia_colada/map_default_overlay.png"
        alt="default overlay" srcset="" style=" object-fit: cover;">
    </div>
    <div class="map-container">
      <!-- <pre>{{ center }}</pre> -->
      <GMapMap v-if="showMap" :center="center"
      :zoom="8" map-type-id="roadmap" class="map-instance" :style="mapStyle"
        :options="mapOptions" ref="myMapRef">
        <div v-if="showMap">
          <GMapCircle v-if="selectedMarker != null && selectedMarker.lat != null"
          :radius="9000" :options="circleOptions"
            :center="{ lat: selectedMarker.lat, lng: selectedMarker.lng }" />
          <!-- <GMapCluster :zoomOnClick="true"> -->
          <GMapMarker :key="`marker-${m.id}`" v-for="(m) in filteredMarkers"
          :position="{ lat: m.lat, lng: m.lng }"
            :icon="m?.icon ?? marker_icon_default" :clickable="true"
             :draggable="false" @click="handleMarkerClick(m)"
            :options="{
              opacity: (selectedState != null && selectedState.isoCode !== m.state) ? 0
                : (selectedMarker != null && selectedMarker.id === m.id) ? 1 : 0.4,
            }" />
          <!-- </GMapCluster> -->
        </div>
      </GMapMap>
    </div>
    <div class="venue-list-container invisible-scrollbars" :class="{ closed: closed }">
      <venue-list-component :markers="filteredMarkersWithDistance"
      :userposition="computedUserCoords"
        @item-click="handleVenueSelect"></venue-list-component>
      <div class="expand" @keypress.enter="closed = false" @click="closed = false" v-if="closed">
        <img class="mobile" src="https://archeio2.nyc3.cdn.digitaloceanspaces.com/malibu/2023_pinia_colada/arrow_down.svg"
          alt="expand">
        <button class="desktop" id="btn_loadmore">Load More</button>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash';
import VenueListComponent from '@/components/VenueListComponent.ce.vue';
import emitter from '@/utils/emitter';
import { State } from 'country-state-city';
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    markers: {
      required: true,
      type: Object,
    },
  },
  components: {
    VenueListComponent,
  },
  data() {
    return {
      closed: true,
      marker_icon_default: 'https://archeio2.nyc3.cdn.digitaloceanspaces.com/malibu/2023_pinia_colada/marker_restaurant_location.png',
      marker_icon_user: 'https://archeio2.nyc3.cdn.digitaloceanspaces.com/malibu/2023_pinia_colada/marker_user_location.png',
      selectedState: null,
      userCoords: null,
      center: { lat: 51.093048, lng: 6.84212 },
      centerCopy: { lat: 51.093048, lng: 6.84212 },
      isMobile: true,
      debouncedResizeHandler: null,
      mapMarkers: [],
      mapLoaded: false,
      showMap: true,
      circleOptions: {
        strokeColor: '#EB5573',
        strokeOpacity: 0.1,
        strokeWeight: 2,
        fillColor: '#EB5573',
        fillOpacity: 0.4,
      },
      mapOptions: {
        styles: [
          {
            featureType: 'all',
            elementType: 'geometry',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'administrative.country',
            elementType: 'geometry',
            stylers: [
              {
                color: '#9e9e9e',
              },
              {
                lightness: 35,
              },
              {
                weight: 0.5,
              },
            ],
          },
          {
            featureType: 'administrative.province',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#bdbdbd',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
              {
                color: '#dadada',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit.station',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#c9c9c9',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
        ],
      },
    };
  },
  watch: {
    async selectedState() {
      if (!this.mapLoaded) {
        return;
      }
      this.showMap = false;
      // setTimeout(() => {
      //   this.showMap = true;
      // }, 0);
      await this.$nextTick();
      this.showMap = true;
    },
    async mapLoaded(newValue) {
      if (!newValue) {
        return;
      }
      this.showMap = false;
      await this.$nextTick();
      this.showMap = true;
    },
    userIPinfo(newValue) {
      if (newValue?.state_name) {
        const stateName = newValue?.state_name ?? null;
        const states = State.getStatesOfCountry('US');
        this.selectedState = states.find((stateObject) => stateName.toLowerCase().trim().replaceAll(' ', '')
          === stateObject.name.toLowerCase().trim().replaceAll(' ', '')) ?? null;
        return;
      }
      this.selectedState = null;
    },
    computedUserCoords(newValue) {
      if (newValue == null) {
        return;
      }
      this.center = { lat: newValue?.lat, lng: newValue.lng };
    },
    filteredMarkers(newValue) {
      // debugger;
      if (!Array.isArray(newValue)) {
        return;
      }
      this.mapMarkers = structuredClone(newValue);
    },
  },
  computed: {
    ...mapState(['selectedMarker', 'userIPinfo']),
    mapStyle() {
      // const { isMobile } = this;
      // const styleString = `width: 100%;
      // display: flex;
      // flex-direction: column;
      // flex: 1; height: 100%;
      // min-height: ${isMobile ? '50vh' : '100vh'};`;
      const styleString = `width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1; height: 100%;
      `;
      return styleString;
    },
    filteredMarkers() {
      let { markers } = this;
      const { selectedState } = this;
      if (!Array.isArray(markers)) {
        markers = [];
      }
      // TODO: filter by expiration date
      const filteredMarkers = markers.filter((el) => {
        if (selectedState != null
          && el.state.toLowerCase().trim() !== selectedState?.isoCode.toLowerCase().trim()) {
          return false;
        }
        return true;
      });
      // debugger;
      // debugger;
      // console.log(filteredMarkers.length);
      // console.log(filteredMarkers);
      return filteredMarkers;
    },
    filteredMarkersWithDistance() {
      const {
        filteredMarkers,
        getDistanceFromLatLonInMiles,
        computedUserCoords,
        // formatDistance,
      } = this;

      const formatDistance = (distanceToMarker) => {
        if (distanceToMarker == null || typeof distanceToMarker !== 'number') {
          return null;
        }
        if (distanceToMarker < 1000) {
          return `${distanceToMarker.toFixed(1)}`;
        }
        return `${(distanceToMarker / 1000).toFixed(1)}k`;
      };

      const newArray = filteredMarkers.map((el) => {
        const copy = structuredClone(el);
        let distanceToMarker = null;
        if (computedUserCoords != null) {
          // eslint-disable-next-line max-len
          distanceToMarker = getDistanceFromLatLonInMiles(computedUserCoords.lat, computedUserCoords.lng, el.lat, el.lng);
          copy.distanceValue = distanceToMarker;
          distanceToMarker = formatDistance(distanceToMarker);
        }
        copy.distance = distanceToMarker;
        return copy;
      });
      return newArray.sort((a, b) => {
        if (a.distanceValue === b.distanceValue) {
          return 0;
        }
        return a.distanceValue < b.distanceValue ? -1 : 1;
      });
    },
    computedMarkers() {
      const { filteredMarkers: markers } = this;
      // const { marker_icon_user: userMarkerIcon } = this;
      // console.log(userMarkerIcon);
      let newMarkers = [];
      // Disabled User Location
      // if (this?.computedUserCoords?.lat != null) {
      //   newMarkers = [
      //     {
      //       userMarker: true,
      //       // icon: null && userMarkerIcon,
      //       // position: {
      //       lat: this.computedUserCoords.lat,
      //       lng: this.computedUserCoords.lng,
      //       // },
      //     },
      //   ];
      // }
      if (Array.isArray(markers)) {
        newMarkers = [...newMarkers, ...markers];
      }
      return newMarkers;
    },
    computedUserCoords() {
      const { userCoords, userIPinfo } = this;
      if (userCoords != null) {
        return userCoords;
      }
      if (userIPinfo?.lct_latitude) {
        const newCoords = {
          lat: +userIPinfo.lct_latitude,
          lng: +userIPinfo.lct_longitude,
        };

        // debugger;
        return newCoords;
      }
      return { lat: 42.667463, lng: -75.970198 };
    },
  },
  methods: {
    ...mapMutations(['setSelectedMarker']),
    getUserPosition() {
      return new Promise((resolve) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            // console.log(position);
            const userCoords = {
              lat: +position.coords.latitude,
              lng: +position.coords.longitude,
            };
            resolve(userCoords);
          });
        } else {
          resolve(false);
        }
      });
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const { deg2rad } = this;
      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(lat2 - lat1); // deg2rad below
      const dLon = deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
        * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    getDistanceFromLatLonInMiles(lat1, lon1, lat2, lon2) {
      const { getDistanceFromLatLonInKm } = this;
      const distanceKm = getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);
      return distanceKm * 0.6213711922;
    },
    handleMarkerClick(marker) {
      const newCenter = { lat: marker.lat, lng: marker.lng };
      // debugger;
      this.center = newCenter;
      this.setSelectedMarker(marker);
      emitter.emit('marker-selected', marker);
    },
    resizeHandler(dimensions) {
      console.log('window resized');
      console.log(dimensions);
    },
    handleVenueSelect(marker) {
      // console.log(marker);
      this.setSelectedMarker(marker);
      const newCenter = { lat: marker.lat, lng: marker.lng };
      this.center = newCenter;
    },
    updateMarkers() {
      const map = this.$refs.myMapRef;
      console.log(map);
      console.log(map.markers);
    },
  },

  beforeUnmount() {
    // window.removeEventListener('resize', this.debouncedResizeHandler);
    // this.debouncedResizeHandler = null;
  },
  mounted() {
    // this.debouncedResizeHandler = _.debounce(this.resizeHandler, 500);
    // window.addEventListener('resize', this.debouncedResizeHandler);

    this.$refs.myMapRef.$mapPromise.then((mapObject) => {
      this.mapLoaded = true;
      console.log('map is loaded now', mapObject);
      // debugger;
      this.getUserPosition().then((res) => { this.userCoords = res; });
    });
    emitter.on('state-selected', (selectedState) => {
      // debugger;
      // const { filteredMarkers } = this;
      if (selectedState != null) {
        const center = {
          lat: +selectedState.latitude,
          lng: +selectedState.longitude,
        };
        this.center = center;
      }
      this.selectedState = selectedState;
      this.setSelectedMarker(null);
      // this.updateMarkers();
    });
  },
};
</script>

<style lang="scss">
div.map-component {
  $split-breakpoint: 1000px;

  position: relative;
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: $split-breakpoint) {
    grid-template-columns: repeat(2, 1fr);
  }

  // display: flex;
  // flex-flow: row;
  // flex: 1;
  // min-height: 100vh;

  * {
    box-sizing: border-box;
  }

  .overlay {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .invisible-scrollbars {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }
  }

  .map-instance {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 40vh;
    // height: 100%;

    @media screen and (min-width: $split-breakpoint) {
      min-height: 100vh;
      height: 100%;
    }
  }

  .map-container {

    // width: 100%;
    @media screen and (min-width: $split-breakpoint) {
      min-height: 100vh;
      // width: 50%;
    }
  }

  .venue-list-container {
    width: 100%;
    overflow-y: scroll;
    height: 60vh;
    position: relative;

    // background-color: grey;
    &.closed {
      height: 200px;
      overflow: hidden;
    }

    @media screen and (min-width: $split-breakpoint) {
      height: 100vh !important;
      // width: 50%;
      overflow-y: scroll;

      .expand {
        // display: none;
      }
    }

    .expand {
      position: absolute;
      display: flex;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      align-items: flex-end;
      justify-content: center;
      // background-color: blue;
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%);

      img {
        padding: 15px;
      }

      &:hover {
        cursor: pointer;
      }

      .mobile {
        display: block;
      }

      .desktop {
        display: none;
      }

      @media screen and (min-width: $split-breakpoint) {
        .mobile {
          display: none;
        }

        .desktop {
          display: block;
        }
      }
    }
  }

  #btn_loadmore {
    width: 192px;
    height: 52.72px;
    border: 3.14754px solid #EB5573;
    border-radius: 6.29508px;
    font-family: 'CubanoItalic';
    font-style: normal;
    font-weight: 400;
    font-size: 22.0328px;
    line-height: 26px;
    text-align: center;
    color: #EB5573;
    cursor: pointer;
    position: relative;
    bottom: 50px;

    &:hover{
      opacity: 0.8;
    }

  }
}
</style>
