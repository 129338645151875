<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <section id="cocktails_section">
    <div class="container_banner" >
      <p>
        Visit our new AR experience to discover recipes, explore our awesome flavors,
        snap a pic in our photo booth, play games to win exclusive prizes—and more.

      </p>
      <div class="container_button">
       <button class="btn_cta3" @click="hreflocation" >Come On In</button>
      </div>
    </div>
  </section>
</template>

<script>
import { State } from 'country-state-city';
import adresses from '@/utils/addresses.json';

const VUE_APP_S3 = 'https://plant.hellosandia.com/img/test/';

export default {
  components: {

  },
  data() {
    return {
      cocktailPinaCola: `${VUE_APP_S3}cocktails/cocktail_pinacolada.png`,
      cocktailCoconutWater: `${VUE_APP_S3}cocktails/cocktail_coconutwater.png`,
      cocktailSlushy: `${VUE_APP_S3}cocktails/cocktail_slushy.png`,
      cocktailSparkling: `${VUE_APP_S3}cocktails/cocktail_sparkling.png`,
      selectedState: null,
      markers: [],
      options: [],
      selected: null,
    };
  },
  computed: {

  },
  methods: {
    hreflocation() {
      const gAnalitycs = {
        value: 'Click_Come_On_AR-UK',
        event_action: 'Click_Come_On_AR-UK',
        event_category: 'Button Click',
        event_label: 'Come_On_AR',
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);

      window.open('https://webar.blippar.com/dowhatevertastesgood/');
    },
  },
  mounted() {
    this.options = State.getStatesOfCountry('US');
    this.markers = adresses.filter((ad) => ad.active === 1);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.container_banner{
  background-image: url("https://plant.hellosandia.com/img/test/banner2.png");
  width: 100%;
  height: 100%;
  padding-bottom: 75px;

  background-repeat: no-repeat;
  background-size: 100%;

  padding-top: 43%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;

  p{
    max-width: 640px;
    text-align: center;
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    font-family: geoxe3 !important;

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    /* or 34px */

    text-align: center;

    color: #23161B;
  }

}

@media screen and (max-width: 600px) {
  .container_banner{
    background-image: url("https://plant.hellosandia.com/img/test/banner2Mobile.png");
    padding-top: 119%;
    p{
      font-size: 18px;
      max-width: 300px;
    }
  }
}

</style>
