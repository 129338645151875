<!-- eslint-disable vuejs-accessibility/media-has-caption -->
<!-- eslint-disable vuejs-accessibility/iframe-has-title -->
<template>
  <section class="sdk-video" >
    <div class="sdk-btm__video" data-ga="YOUTUBE VIDEO" data-ga-event="VIDEO PLAYS"
      data-ga-value="View Cocktail Video Clicks Play Button">

      <video
        width="250"
        class="logo_malibu"
        playsinline muted loop autoplay
        @click="clickVideo"
        @keypress.enter="clickVideo"
        v-if="!isYoutube">
        <source :src="video" type="video/mp4">
      </video>

      <div class="youtube-container" v-if="isYoutube"
      @click="clickIframe"
      @keypress.enter="clickIframe"
      >
      <iframe width="100%"
      height="315"
      class="responsive-iframe"
      :src="video + '?autoplay=1&mute=1&enablejsapi=1'"
      frameborder="0"
      allowfullscreen
      ref="customiframe"
      ></iframe>
    </div>

        <img :src="logo_malibu" alt=""
        :class="iconMobile ? 'logo_malibu' : 'logo_malibu d-none-mobile'"
        :style="!icon?'display:none;': ''"/>
    <!--
      <div class="container_description_banner" v-if="text">
        <h2>
          ESCAPE WITH THE
          OFFICIAL SONG OF
          SUMMER—AND ENJOY A
          MALIBU PIÑA COLADA
          WHILE YOU'RE AT IT!
        </h2>
        <p>
          This iconic remake by Jax is just what your summer playlist needs!
        </p>
      </div>
    -->
    </div>
  </section>
</template>

<script>

const VUE_APP_S3 = 'https://plant.hellosandia.com/img/test/';

export default {
  props: [
    'video',
    'text',
    'isYoutube',
    'icon',
    'iconMobile',
  ],
  data() {
    return {
      html5Clicked: false,
      music: `${VUE_APP_S3}music.png`,
      logo_malibu: `${VUE_APP_S3}logo_malibu.svg`,
      selectedState: null,
    };
  },
  methods: {
    clickIframe() {
      console.log('click!');
    },
    clickVideo() {
      if (this.html5Clicked) {
        return;
      }
      this.html5Clicked = true;
      console.log('click html5 video!');
      const gAnalitycs = {
        value: 'Click_Play Button-UK',
        event_action: 'Click_Play Button-UK',
        event_category: 'Video Button Click',
        event_label: `Play Button${this.video}`,
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);
    },
    trackIframeClick() {
      const message = this.$refs.customiframe;
      window.focus();

      window.addEventListener('blur', () => {
        if (this.html5Clicked) {
          return;
        }
        this.html5Clicked = true;
        setTimeout(() => {
          console.log('Click Iframe');
          const gAnalitycs = {
            value: 'Click_Play Button-UK',
            event_category: 'Video Button Click',
            event_label: `Play Button${this.video}`,
            event_action: 'Click_Play Button-UK',
          };

          if (document.activeElement.tagName === 'MALIBU-PINA-COLADA') {
            message.textContent = `clicked ${Date.now()}`;
            this.$gtag.event(gAnalitycs.value, gAnalitycs);
          }
          // if (document.activeElement.tagName === 'IFRAME') {
          //   message.textContent = `clicked ${Date.now()}`;
          //   console.log('clicked');
          // }
        });
      }, { once: false });
    },
  },
  mounted() {
    this.html5Clicked = false;
    if (this.isYoutube) {
      this.trackIframeClick();
    }
  },
};
</script>

<style lang="scss">

.youtube-container {
  background-color: red;
}

.logo_malibu{
  position: absolute;
    top: 25px;
    left: 40px;
    @media screen and (max-width: 600px) {
      right: 30%;
      left: auto;

    }
  }
.container_description_banner {
  position: absolute;
  top: 0;
  z-index: 9999;
  display: grid;
  align-content: center;
  justify-content: start;
  align-items: start;
  justify-items: start;
  text-align: initial;
  top: 50%;
  bottom: 50%;
  left: 10%;

  h2 {
    font-family: 'CubanoItalic';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 77px;
    color: #07C0B4;
    max-width: 768px;
  }

  p {
    font-family: 'geoxe3';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    color: #23161B;
    max-width: 449px;
  }
}

@media screen and (max-width: 1200px) {
  .container_description_banner {
    h2{
      font-size: 49px;
      line-height: 43px;

    }
  }
}

@media screen and (max-width: 900px) {

  .container_description_banner {
    padding: 20px;
    justify-content: center;
    left: auto;
    right: auto;
    h2{
      font-size: 36px;
      font-size: 36px;
      line-height: 43px;
      text-align: center;
    }
    img{
      margin: 0 auto;
    }
    p{
      text-align: center;
      font-size: 18px;
      margin: 0 auto;
    max-width: 270px;
    }
  }
}
.sdk-btm__video {
  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    .iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }
}

.sdk-main div[data-page=bornToMix] .sdk-video .sdk-btm__video{
  position: relative !important;
  width: 100% !important;
  padding-bottom: 56.25% !important;
  padding-top: 0 !important;
  video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

  .d-none-mobile{
    background-color: red;
      display: none;
  }

</style>
