import defineCustomElement from './defineCustomElementWithStyles';
import AppRoot from './AppRoot.ce.vue';
import store from './store';
import router from './router';

customElements.define(
  'malibu-pina-colada',
  defineCustomElement(AppRoot, {
    plugins: [store, router],
  }),
);
