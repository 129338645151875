import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    selectedMarker: null,
    userIPinfo: null,
  },
  getters: {
  },
  mutations: {
    setSelectedMarker(state, marker) {
      state.selectedMarker = marker;
    },
    setUserIPinfo(state, newValue) {
      state.userIPinfo = newValue;
    },
  },
  actions: {
    async updateIP({ commit }) {
      // const url = 'https://api.geolocation.debrain.cloud/public/location/47.148.67.66';
      const url = 'https://api.geolocation.debrain.cloud/public/location/';
      // const url = 'https://api.geolocation.debrain.cloud/public/location/47.148.67.66';
      const { data } = await axios.get(url);
      // console.log(data);
      // debugger;
      commit('setUserIPinfo', data);
    },
  },
  modules: {
  },
});
