<template>
  <div class="venue-list-component">
    <h1 class="title" v-if="markers.length === 0">No locations found</h1>
    <!-- <pre>{{ selectedMarker }}</pre> -->
    <ul class="venue-list-container invisible-scrollbars" v-show="markers?.length">
      <li
      class="venue-list-item"
      v-for="(venue) in markers"
      :key="`venue-item-${venue.id}`"
      :data-marker-id="venue.id"
      :ref="`venueItem_${venue.id}`"
      :class="{
        'selected': (selectedMarker != null && selectedMarker.id === venue.id),
        }"
      @click="handleVenueClick(venue)"
      @keypress.enter="handleVenueClick(venue)"
      >
        <div class="venue-info">
          <div class="info-container">
            <h1 class="venue-name">{{ venue.restaurant }}</h1>
            <p class="venue-address">
              {{ venue.address }}<br>
              {{ venue.city }}, {{ venue.state }} {{ venue.zip }}
            </p>
            <div class="directions-container">
              <img src="https://archeio2.nyc3.cdn.digitaloceanspaces.com/malibu/2023_pinia_colada/icon_directions3.svg" alt="icon directions">
              <a
                :href="generateDriveToLink(venue)"
                target="_blank"
                rel="noopener noreferrer">
                  Directions
              </a>
            </div>
          </div>
        </div>
        <div class="venue-distance">
          <span class="distance">{{ venue.distance ?? 0 }}</span>
          <span>miles</span>
        </div>
      </li>
    </ul>
    <!-- <pre>{{ markers }}</pre> -->
  </div>
</template>
<script>
import emitter from '@/utils/emitter';
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    markers: {
      required: true,
      type: Object,
    },
    userposition: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      currentUserPosition: null,
    };
  },
  computed: {
    ...mapState(['selectedMarker']),
  },
  watch: {
    userposition(newValue) {
      if (newValue == null) {
        return;
      }
      this.currentUserPosition = newValue;
    },
    // selectedMarker(newValue) {
    //   // console.log('SCROLL LIST::', newValue);
    // },
  },
  methods: {
    ...mapMutations(['setSelectedMarker']),
    handleVenueClick(venue) {
      this.setSelectedMarker(venue);
      this.$emit('item-click', venue);
    },
    generateDriveToLink(marker) {
      const { currentUserPosition } = this;
      const { lat, lng } = marker;
      let link = '';
      if (!currentUserPosition) {
        link = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
        return link;
      }
      link = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
      // link = `https://www.google.com/maps/dir/?api=1&origin=${currentUserPosition.lat},${currentUserPosition.lng}&destination=${lat},${lng}`;
      return link;
    },
  },
  mounted() {
    emitter.on('marker-selected', (marker) => {
      // console.log(marker);
      const referenceString = `venueItem_${marker.id}`;
      const [objectReference] = this.$refs[referenceString];
      // console.log(objectReference);
      // debugger;
      objectReference.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    });
  },
};
</script>
<style lang="scss">
.venue-list-component {
  // $primary-color: #FF7C00;
  $primary-color: #EB5573;

  h1.title {
    margin: 0;
    font-family: geoxe3, 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    // line-height: 140%;
    line-height: 120%;
    text-align: center;
    margin: 25px auto;
  }

  .invisible-scrollbars {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }
  }

  ul {
    list-style-type: none;
    display: flex;
    flex-flow: column;
    margin: 0;
    scroll-behavior: smooth;

    li {
      scroll-behavior: smooth;
      margin: 0;
      transition: all 125ms ease-in-out;
      display: flex;
      // flex: 1;
      flex-flow: row wrap;
      // align-items: baseline;
      align-items: center;
      padding: 15px 15px 15px 15px;

      &.selected {
        cursor: pointer;
        background-color: rgba($primary-color, 0.1);
      }
      &:hover {
        cursor: pointer;
        background-color: rgba($primary-color, 0.2);
      }

      $distance-container-width: 80px;
      .venue-info {
        display: flex;
        flex-direction: row;
        flex:1;
        // width: calc(100% - $distance-container-width);
        text-align: left;
        align-items: baseline;

        .info-container {
          display: flex;
          flex-direction: column;
          // width: calc(100% - $distance-container-width);
          text-align: left;
          align-items: baseline;
        }

        h1.venue-name {
          // margin: 0 0 5px 0;
          margin: 0;
          font-family: 'Geoxe3', Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          // line-height: 140%;
          line-height: 120%;
          text-align: left;
        }
        p.venue-address {
          font-family: 'Geoxe3', Montserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #353535;
          margin: 0;
        }
      }

      .venue-distance {
        display: flex;
        flex-direction: column;
        // width: $distance-container-width;
        justify-content: baseline;

        span {
          font-family: 'Geoxe3', Montserrat;
          font-style: normal;
          font-weight: 300;
          font-size: 21.469px;
          // line-height: 140%;
          line-height: 100%;
          /* or 30px */

          text-align: center;
          text-transform: lowercase;

          color: #000000;
          &.distance {
            font-family: 'Geoxe3', Montserrat;
            font-style: normal;
            font-weight: 700;
            font-size: 35.7817px;
            line-height: 140%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #000000;
          }
        }
      }

      .directions-container {
        display: flex;
        align-items: center;
        margin-top: 5px;
        padding: 0px 10px;
        border-left: 1.5px solid rgba(grey, 0.5);
        gap: 5px;

        a {
          color: $primary-color;
          text-decoration: none;
          transition: all 125ms;
          font-family: 'Geoxe3', Montserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
            opacity: 0.6;
          }
        }
      }

    }
  }
}
</style>
