<template>
  <div id="app">
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/network">Network</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</div>
</template>

<script>
// eslint-disable vue/no-unused-components
import HomeView from '@/views/HomeView.ce.vue';
import { mapActions } from 'vuex';

const VUE_APP_S3 = 'https://archeio2.nyc3.cdn.digitaloceanspaces.com/malibu/2023_pinia_colada/';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    HomeView,
  },
  data() {
    return {
      deferredPrompt: null,
      showInstallPrompt: false,
    };
  },
  methods: {
    ...mapActions(['updateIP']),
    registerFonts() {
      const css = `
      @font-face {
        font-family: CubanoItalic;
        src: url("https://plant.hellosandia.com/img/test/fonts/CubanoNuevoItalic.otf") format("opentype");
      }

      @font-face {
        font-family: 'geoxe3';
        src: url('${VUE_APP_S3}geoxe3.woff2') format('woff2'),
            url('${VUE_APP_S3}geoxe3.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
      `;
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      head.appendChild(style);
      style.type = 'text/css';
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    },
  },
  created() {
    // this.updateIP();
    this.registerFonts();
  },
  mounted() {
    console.log('mounted');
    this.$router.push({ name: 'home' });
  },
};
</script>

<style lang="scss">
@import '@/styles/style.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 1600px;
  margin: 0 auto;

  * {
    box-sizing: border-box;
  }

  .invisible-scrollbars {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
